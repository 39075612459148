import "./index.css";

import { noawait } from "@smartrr/shared/utils/noawait";
import { useRef, useState } from "react";

import { redirectToShopifyAuth } from "@vendor-app/utils/shopify/redirectToShopifyAuth";

export function LoginPage() {
  const [input, setInput] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  const focusOnInput = () => {
    inputRef.current?.focus();
  };

  return (
    <div className="smartrr-login-page-container">
      <div className="smartrr-login-gradient">
        <img src="/smartrr-new-inverted.svg" width={233} />
      </div>

      <div className="smartrr-login-card-container">
        <form
          className="smartrr-login-card"
          onSubmit={event => {
            event.preventDefault();

            if (input.length > 0) {
              noawait(() => redirectToShopifyAuth(input, false));
            }
          }}
        >
          <h1 className="smartrr-login-card-header">Login with your Shopify domain</h1>

          <p className="smartrr-login-card-subheader">Shopify Store URL</p>
          <div className="smartrr-login-card-input messina-default" onClick={() => focusOnInput()}>
            <input
              onChange={e => setInput(e.target.value)}
              className="loginInput"
              autoComplete="off"
              autoCapitalize="off"
              autoCorrect="off"
              autoFocus
              ref={inputRef}
            />
            <span>.myshopify.com</span>
          </div>

          <button type="submit" className={`smartrr-login-card-cta ${input.length > 0 ? "active" : ""}`}>
            Log In
          </button>

          <div className="smartrr-login-card-signup messina-default">
            Don&apos;t have an account? Download Smartrr on&nbsp;
            <a href="https://apps.shopify.com/smartrr">Shopify&apos;s app store.</a>
          </div>
        </form>
      </div>
    </div>
  );
}
